import React, { useState } from "react";
import ErweitertRunderTeppich from "../components/erweitert/erweitertRunderTeppich";
import RunderTeppichFoto from "../components/Fotos/RunderTeppichFoto";

function RunderTeppich() {
  const [diameter, setDiameter] = useState("");
  const [area, setArea] = useState(0);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9,.]*$/;

    if (regex.test(inputValue)) {
      setDiameter(inputValue);
      const formattedValue = inputValue.replace(",", ".");
      const radiusInCm = parseFloat(formattedValue) / 2;
      const radiusInM = radiusInCm / 100;
      const calculatedArea = Math.PI * radiusInM * radiusInM;
      setArea(calculatedArea);
    }
  };

  // Materialverbräuche pro m² für verschiedene Materialien in Kilogramm
  const materialVerbraeucheKg = [
    {
      name: "Bändchengarn XL",
      name_kurz: "Bändchengarn XL",
      verbrauch: 1.65,
      link: "https://www.textilo-shop.com/baendchengarn-genormte-baumwoll-recyclinggarne",
    },
    {
      name: "Kordel",
      name_kurz: "Kordel",
      verbrauch: 3.5,
      link: "https://www.textilo-shop.com/kordel-kordelgarn",
    },
    {
      name: "Strickschnur",
      name_kurz: "Strickschnur",
      verbrauch: 3.5,
      link: "https://www.textilo-shop.com/strickschnur-",
    },
    {
      name: "Textilo-Textilgarn",
      name_kurz: "Textilo",
      verbrauch: 3.7,
      link: "link_zu_textilo_textilgarn_material",
    },
    {
      name: "Wollschnur",
      name_kurz: "Wollschnur",
      verbrauch: 2.5,
      link: "https://www.textilo-shop.com/wollschnur",
    },

    {
      name: "Filzseil",
      name_kurz: "Filzseil",
      verbrauch: 5.26,
      link: "https://www.textilo-shop.com/filzseil",
    },
  ];

  return (
    <div>
      <div className="mb-12">
        <div>
          <div className="flex flex-wrap mx-3 mb-1">
            <div className="w-full max-w-full px-3 my-2 mx-auto">
              <RunderTeppichFoto />
            </div>
          </div>
          {/* Eingabe Durchmesser */}
          <div className="flex items-center">
            <label
              className="block text-textiloFont text-1xl text-left ml-0 pr-1"
              htmlFor="diameterInput"
            >
              &Oslash; (cm):
            </label>
            <input
              id="diameterInput"
              type="text"
              value={diameter}
              onChange={handleInputChange}
              maxLength={4}
              className="my-2 p-0 text-xl border border-gray-300 bg-gray-300 rounded focus:outline-none focus:border-textiloSeaDark w-16"
            />
          </div>
          <p className="text-text-textiloFont mb-3 text-2xl text-textiloSeaDark mt-2">
            {area ? area.toFixed(2) + " m²" : "0,00 m²"}
          </p>
          <hr className="mb-3 border-b-1 border-textiloDesign"></hr>
          {/* Ausgabe Materialverbrauch  */}
          <div>
            <h2 className="text-textiloFont text-2xl text-left ">
              Materialverbrauch
            </h2>
            <ul
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {materialVerbraeucheKg.map((material) => (
                <li
                  className="text-textiloFont text-lg"
                  key={material.name}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {material.name}:{" "}
                  {(area * material.verbrauch * 1000).toFixed(0)} g
                  {/* Füge hier das Badge mit dem Link ein */}
                  <a
                    href={material.link}
                    className="bg-textiloBtn mb-1 text-gray-100 text-base font-semibold me-2 px-2.5 py-0.5 rounded border border-gray-100 inline-flex items-center justify-center"
                  >
                    {material.name_kurz} im Shop
                  </a>
                </li>
              ))}
            </ul>
            <ErweitertRunderTeppich />
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default RunderTeppich;
